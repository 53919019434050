@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply font-main box-border m-0 p-0;
  }
  body {
    @apply dark:bg-slate-800 bg-white
  }
  .input-default{
    @apply w-full text-xs placeholder:text-xs text-gray-700;
  }
}

@layer utilities {
  @media print {
    .no-print {
      display: none;
    }
    @page {
      margin: 0;
    }
    body {
      margin: 0;
    }
  }
}

.scrollWidth-thin,
.overflow-y-auto,
.overflow-x-auto,
.overflow-auto{
  scrollbar-width: thin;
}
.CookieConsent{
  z-index: 1201 !important;
}
.scrollWidth-normal{
  scrollbar-width: auto !important;
}
.react-flow__attribution{
  display: none;
}
.remove-empty {
  *:empty{
    display: none;
  }
  p:last-child{
    margin-bottom: 0 !important;
  }
}
.MuiInputLabel-asterisk {
  color: red;
}
.all-inline * {
  display: inline !important;
}
a,
button{
  text-decoration: none;
  transition: .1s ease all;
}

input:focus,
textarea:focus{
  outline: none;
  box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.tab-draggable{
  transform: translateY(0) !important;

  button{
    top: 0 !important;
    left: 0 !important;
  }
}

.fullscreen-active{
  background: #FFF;
}

#copilot.fullscreen-active .chat,
#copilot.fullscreen-active .chat-sidebar{
  height: calc(100vh - 145px) !important;
}

#inbox.fullscreen-active #inbox-container{
  height: 100dvh !important;
}
#inbox.fullscreen-active .chat{
  height: calc(100vh - 225px) !important;
}

.chat-typing{
  font-weight: 900;
  line-height: 1rem;
  font-size: 35px;
  color: #555;

  p, pre{
    font-size: 35px !important;
    animation: pulse 0.7s ease-in-out infinite alternate-reverse;
  }
}
@keyframes pulse {
  100%{
    opacity: 0.5;
  }
}

.input-cursor {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: #777;
  margin-left: 3px;
  vertical-align: text-bottom;
  animation: blink 0.6s step-start infinite alternate;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.chat-dialog{
  &:before {
    content:"";
    position:absolute;
    width:0px;
    height:0px;
    border: 10px solid;
    border-color: transparent #888 transparent transparent;
    left:-20px;
    top: 50%;
    margin-top: -10px;
  }
}
.dark .chat-dialog{
  &:before {
    border-color: transparent #334155 transparent transparent;
  }
}

.user-chat-dialog{
  &:before {
    content:"";
    position:absolute;
    width:0px;
    height:0px;
    border: 10px solid;
    border-color: transparent transparent transparent #96ceff;
    right:-20px;
    top: 50%;
    margin-top: -10px;
  }
}
.testing-tool {
  .user-chat-dialog:before {
    border-color: transparent transparent transparent #e5e7eb;
  }
  .chat-dialog:before {
    border-color: transparent #f3f4f6 transparent transparent;
  }
}
.dark .user-chat-dialog{
  &:before {
    border-color: transparent transparent transparent #1E293B;
  }
}
.filter-black {
  filter: brightness(0%) opacity(0.7);
}
.filter-secondary-color{
  filter: invert(17%) sepia(87%) saturate(2898%) hue-rotate(222deg) brightness(88%) contrast(83%);
}
.chat-message p{
  margin-bottom: 10px;
}
.loading-testingtool-message:before {
  border-color: #0000 #e5e7eb #0000 #0000 !important;
}
.MuiTabs-scrollButtons.Mui-disabled {
  display: none;
}